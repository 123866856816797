import styled from "@emotion/styled";
import { css } from "@emotion/core";
import {
  margin,
  textAlign,
  color,
  ColorProps,
  MarginProps,
  TextAlignProps,
} from "styled-system";

import { Theme } from "../../theme";

type HeadingType = Theme & MarginProps & TextAlignProps & ColorProps;

export const HeaderStyles = (props: HeadingType) =>
  css`
    color: ${props.theme.colors.textPrimary};
    font-family: ${props.theme.fontFamily};
    font-weight: bold;
    line-height: 1.2;
    margin: 0;
  `;

export const H1 = styled.h1<HeadingType>`
  font-size: 55px;
  ${HeaderStyles};
  ${margin};
  ${textAlign};
  ${color};

  ${(props) => props.theme.mediaQueries.sm} {
    font-size: 30px;
  }
`;

export const H2 = styled.h2<HeadingType>`
  font-size: 48px;
  ${HeaderStyles};
  ${margin};
  ${textAlign};
  ${color};
  ${(props) => props.theme.mediaQueries.sm} {
    font-size: 30px;
  }
`;

export const H3 = styled.h3<HeadingType>`
  color: ${(props) => props.theme.colors.textPrimary};
  font-family: ${(props) => props.theme.fontFamily};
  font-weight: bold;
  font-size: 24px;
  line-height: 1.3;
  margin: 0;
  ${margin};
  ${textAlign};
  ${color};
`;
