import React from 'react'

import SEO from '../components/seo'
import { GlobalWrapper } from '../components/GlobalStyles'
import { Header } from '../features/Header/Header'
import { Hero } from '../features/Hero'
import { LearnToDance } from '../features/LearnToDance/LearnToDance'
import { TeamBlock } from '../features/Team/Team'
import { Footer } from '../features/Footer/Footer'
import { FAQ } from '../features/FAQ/List'
import { SocialApprovement } from '../features/SocialApprovement'
import { ProjectContextProvider } from '../ProjectContext'

const IndexPage = () => (
  <ProjectContextProvider>
    <GlobalWrapper>
      <SEO title="Home" />
      <Header />
      <Hero />
      <LearnToDance />
      <SocialApprovement />
      <FAQ />
      <TeamBlock />
      <Footer />
    </GlobalWrapper>
  </ProjectContextProvider>
)

export default IndexPage
