import React from 'react'
import styled from '@emotion/styled'

import { Item, MediaItem } from './MediaItem'

import { Text } from '../../components/Typography'
import FbLogo from '../../images/svg/fb.svg'
import InstragramLogo from '../../images/svg/instagram.svg'
import LinkedinLogo from '../../images/svg/linkedin.svg'
import { Theme } from '../../theme'

const StyledLink = styled.a<Theme>`
  text-decoration: none;
  color: ${(props) => props.theme.colors.textSecondary};
  &:hover {
    text-decoration: underline;
  }
`

const SocialBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 20px;
`

const items: MediaItem[] = [
  {
    altText: 'facebook logo',
    img: FbLogo,
    link: 'https://www.facebook.com/DanclyOfficial'
  },
  {
    altText: 'instagram logo',
    img: InstragramLogo,
    link: 'https://www.instagram.com/danclyofficial/'
  },
  {
    altText: 'linkedin logo',
    img: LinkedinLogo,
    link: 'https://www.linkedin.com/company/33177344/admin/'
  }
]

type Props = {
  color?: string
}

export const MediaList: React.FC<Props> = ({ color = 'textPrimary' }) => (
  <div>
    <SocialBlock>
      {items.map((item, index) => (
        <Item {...item} key={index} />
      ))}
    </SocialBlock>
    <StyledLink href="mailto:hello@dancly.com">
      <Text textAlign="center" color={color}>
        hello@dancly.com
      </Text>
    </StyledLink>
  </div>
)
