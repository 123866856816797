import React from 'react'
import { Global, css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import emotionNormalize from 'emotion-normalize'

import { theme } from '../theme'
import { ProjectContext } from '../ProjectContext'

export const GlobalWrapper: React.FC = ({ children }) => (
  <ProjectContext.Consumer>
    {({ isMenuVisible }) => (
      <ThemeProvider theme={theme}>
        <Global
          styles={css`
            ${emotionNormalize}

            @import url("https://p.typekit.net/p.css?s=1&k=tgg1ucs&ht=tk&f=10881.10882.10884.10885.15357.15358.15361.15362.32874.32875&a=712216&app=typekit&e=css");

            @font-face {
              font-family: 'futura-pt';
              src: url('https://use.typekit.net/af/309dfe/000000000000000000010091/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                  format('woff2'),
                url('https://use.typekit.net/af/309dfe/000000000000000000010091/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                  format('woff'),
                url('https://use.typekit.net/af/309dfe/000000000000000000010091/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
                  format('opentype');
              font-display: auto;
              font-style: normal;
              font-weight: 700;
            }

            @font-face {
              font-family: 'futura-pt';
              src: url('https://use.typekit.net/af/eb729a/000000000000000000010092/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                  format('woff2'),
                url('https://use.typekit.net/af/eb729a/000000000000000000010092/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                  format('woff'),
                url('https://use.typekit.net/af/eb729a/000000000000000000010092/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
                  format('opentype');
              font-display: auto;
              font-style: italic;
              font-weight: 700;
            }

            @font-face {
              font-family: 'futura-pt';
              src: url('https://use.typekit.net/af/9b05f3/000000000000000000013365/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                  format('woff2'),
                url('https://use.typekit.net/af/9b05f3/000000000000000000013365/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                  format('woff'),
                url('https://use.typekit.net/af/9b05f3/000000000000000000013365/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
                  format('opentype');
              font-display: auto;
              font-style: normal;
              font-weight: 400;
            }

            @font-face {
              font-family: 'futura-pt';
              src: url('https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                  format('woff2'),
                url('https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                  format('woff'),
                url('https://use.typekit.net/af/cf3e4e/000000000000000000010095/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
                  format('opentype');
              font-display: auto;
              font-style: italic;
              font-weight: 400;
            }

            html,
            body {
              font-family: futura-pt;
              scroll-behavior: smooth;
              overflow: ${isMenuVisible ? 'hidden' : 'auto'};
            }

            * {
              box-sizing: border-box;
            }
          `}
        />
        {children}
      </ThemeProvider>
    )}
  </ProjectContext.Consumer>
)
