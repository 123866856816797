import React from 'react'
import styled from '@emotion/styled'
import { useMediaQuery } from 'react-responsive'

import { Hamburger } from './Hamburger'
import { MobileMenu } from './MobileMenu'

import { Theme, theme } from '../../theme'
import { Container, Section } from '../../components/Layout'
import { Text } from '../../components/Typography'
import { Flex } from '../../components/Layout/Flex'
import { ProjectContext } from '../../ProjectContext'

const HeaderWrapper = styled.div<Theme>`
  margin: 0 auto;
  padding: 0 1.7143rem;
  position: sticky;
  max-width: 100%;
  top: 0;
  z-index: ${(props) => props.theme.zIndex.header};
  padding: 0;
  background: ${(props) => props.theme.colors.canvas};
`

const HeaderItem = styled.a<Theme>`
  margin-right: 40px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.textPrimary};

  &:last-child {
    margin-right: 0;
  }
`

const StyledLogo = styled.a<Theme>`
  text-decoration: none;
  font-size: 40px;
  font-weight: bold;
  margin: 0;
  color: ${(props) => props.theme.colors.textPrimary};
`

const header = [
  {
    title: 'About Dancly',
    url: '#aboutUs'
  },
  {
    title: 'Our Ambassadors',
    url: '#ourAmbassadors'
  },
  {
    title: 'FAQ',
    url: '#faq'
  },
  {
    title: 'The team',
    url: '#team'
  },
  {
    title: 'Contact us',
    url: '#contactUs'
  }
]

export const Header: React.FC = () => {
  const isBigScreen = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg})`
  })

  return (
    <ProjectContext.Consumer>
      {({ toggleMenuVisibility, isMenuVisible }) => (
        <>
          <HeaderWrapper>
            <Section py={{ _: '20px' }}>
              <Container>
                <Flex justifyContent="space-between">
                  <StyledLogo href="#hero">Dancly</StyledLogo>
                  <Flex alignItems="center">
                    {isBigScreen &&
                      header.map(({ title, url }, key) => (
                        <HeaderItem href={url} key={key}>
                          <Text>{title}</Text>
                        </HeaderItem>
                      ))}
                    {!isBigScreen && (
                      <Hamburger
                        onClick={toggleMenuVisibility}
                        isActive={isMenuVisible}
                      />
                    )}
                  </Flex>
                </Flex>
              </Container>
            </Section>
          </HeaderWrapper>
          {!isBigScreen && isMenuVisible && (
            <MobileMenu onClick={toggleMenuVisibility} menuItems={header} />
          )}
        </>
      )}
    </ProjectContext.Consumer>
  )
}
