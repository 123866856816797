import React from 'react'
import styled from '@emotion/styled'

import { Item } from './Item'

import { Container, Section } from '../../components/Layout'
import { H2 } from '../../components/Typography'
import Live from '../../images/svg/003-live.svg'
import Ticket from '../../images/svg/002-ticket.svg'
import Location from '../../images/svg/001-location.svg'
import Dancers from '../../images/dancers.png'
import { Theme } from '../../theme'

const items = [
  {
    img: Location,
    altText: 'House frame',
    text: 'Find dance events by location at any given moment'
  },
  {
    img: Ticket,
    altText: 'Dancing lady',
    text: 'Buy and store your tickets in one place '
  },
  {
    img: Live,
    altText: 'Apple and google play icons',
    text: 'Stream online events worldwide'
  }
]

const ItemWrapper = styled.div<Theme>`
  display: grid;
  grid-gap: 110px;
  grid-template-columns: repeat(3, 1fr);

  ${(props) => props.theme.mediaQueries.sm} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto);
    grid-gap: 65px;
  }
`

const StyledSection = styled(Section)<Theme>`
  background: rgb(203, 174, 152);
  background: linear-gradient(
      196deg,
      rgba(203, 174, 152, 0.604079131652661) 0%,
      rgba(13, 5, 30, 0.5032387955182073) 49%
    ),
    url(${Dancers});
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 560px;

  ${(props) => props.theme.mediaQueries.sm} {
    background-position: 34% 0;
  }
`

export const LearnToDance: React.FC = () => (
  <StyledSection id="aboutUs" py={{ _: '80px', lg: '110px' }}>
    <Container>
      <H2
        mb={{ _: '65px', lg: '80px' }}
        textAlign="center"
        color="textSecondary"
      >
        Discover dance events on a global scale
      </H2>
      <ItemWrapper>
        {items.map((item, key) => (
          <Item {...item} key={key} />
        ))}
      </ItemWrapper>
    </Container>
  </StyledSection>
)
