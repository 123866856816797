import styled from '@emotion/styled'
import {
  margin,
  fontSize,
  fontWeight,
  textAlign,
  color,
  ColorProps,
  MarginProps,
  TextAlignProps,
  FontSizeProps,
  FontWeightProps
} from 'styled-system'

import { Theme } from '../../theme'

type TextType = Theme &
  MarginProps &
  TextAlignProps &
  FontSizeProps &
  FontWeightProps &
  ColorProps

export const Text = styled.p<TextType>`
  color: ${(props) => props.theme.colors.textPrimary};
  font-family: ${(props) => props.theme.fontFamily};
  line-height: 1.2;
  font-size: 20px;
  margin: 0;
  ${margin}
  ${fontSize}
  ${fontWeight}
  ${textAlign}
  ${color}

  ${(props) => props.theme.mediaQueries.sm} {
    font-size: 18px;
    ${fontSize}
  }
`
