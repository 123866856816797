import React, { useState } from "react";
import styled from "@emotion/styled";
import { Theme } from "@theme";
import { Container, Section } from "../components/Layout";
import HeroImage from "../images/herophone.png";
import BgDesktop from "../images/svg/Groupbg12.svg";
import BgMb from "../images/svg/BgMb.svg";
import { H1, Text } from "../components/Typography";
import CheckMarkImage from "../images/svg/check-mark.svg";
import CheckMarkSuccessImage from "../images/svg/Path2.svg";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import firebase from "gatsby-plugin-firebase";

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Email address is invalid.")
    .required("* Please enter your mail for free beta."),
});
const Wrapper = styled.div<Theme>`
  display: grid;
  grid-template-columns: 1.01fr 1fr;
  grid-gap: 0;
  margin-bottom: 10px;

  ${(props) => props.theme.mediaQueries.sm} {
    grid-template-columns: 1fr;
    align-items: center;
    grid-gap: 0;
  }
`;

const TextWrapper = styled.div<Theme>`
  padding-top: 30px;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.mediaQueries.sm} {
    align-items: center;
    text-align: center;
  }
`;

const Image = styled.img<Theme>`
  width: 100%;
  margin-left: 17%;
  @media screen and (min-width: 500px) {
    margin-left: 20%;
  }
  @media screen and (min-width: 550px) {
    margin-left: 22%;
  }
  @media screen and (min-width: 600px) {
    margin-left: 25%;
  }
  ${(props) => props.theme.mediaQueries.lg} {
    margin-left: 30%;
  }
  ${(props) => props.theme.mediaQueries.sm} {
    max-width: 400px;
  }
`;

const CheckMark = styled.img`
  max-width: 26px;
  margin-right: 5px;
`;

const CheckMarkWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonSubmit = styled.button<Theme>`
  margin: auto;
  border-radius: 5px;
  height: 50px;
  width: 183px;
  border-radius: 5px;
  background: rgb(203, 174, 152);
  border: none;
  color: white;
  font-size: 20px;
  transition: background 0.2s linear;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 40px;
  text-decoration: none;
  outline: none;
  ${(props) => props.theme.mediaQueries.lg} {
    width: 144px;
    margin: 0;
    margin-left: auto;
  }

  &:hover {
    background: rgb(166, 142, 129);
  }
  &:disabled {
    background: rgb(166, 142, 129);
    cursor: not-allowed;
  }
`;

const InputEmail = styled.input`
  border-radius: 5px;
  border: 1px solid #d3cccc;
  outline: none;
  width: 100%;
  padding: 12px 20px;
  font-size: 20px;
  &::placeholder {
    font-size: 20px;
    color: #d2d0d4;
  }
`;

const FormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const InputWrapper = styled.div<Theme>`
  width: 100%;
  ${(props) => props.theme.mediaQueries.lg} {
    flex: 1 !important;
  }
`;

const ButtonWrapper = styled.div<Theme>`
  width: 100%;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 50px;
  ${(props) => props.theme.mediaQueries.lg} {
    width: 154px;
    margin-top: 0;
    margin-bottom: 0 !important;
  }
`;

const TextError = styled.p<Theme>`
  color: #e02020;
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 18px;
  opacity: 0.57;
  ${(props) => props.theme.mediaQueries.lg} {
    font-size: 20px;
  }
`;

const TextSuccess = styled.p<Theme>`
  color: #14d1a3;
  margin: 10px 0 0 0;
  padding: 0;
  font-size: 18px;
  ${(props) => props.theme.mediaQueries.lg} {
    font-size: 20px;
  }
`;

const CheckMarkSuccess = styled.img`
  margin: 0 5px;
`;

const Loader = styled.span`
  display: inline-block;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #333;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SectionWrapper = styled.section<Theme>`
  background-image: url(${BgDesktop});
  background-repeat: no-repeat;
  background-position-x: 33.5vw;
  background-position-y: 200%;
  ${(props) => props.theme.mediaQueries.sm} {
    background-image: url(${BgMb});
    background-size: cover;
    background-position: center;
  }
`;

export const Hero: React.FC = () => {
  const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmitForm = async (values) => {
    setLoading(true);
    try {
      await firebase.firestore().collection("users").add({
        create: new Date(),
        email: values.email,
      });
      setIsSubmitSuccess(true);
      setIsSubmitError(false);
      setLoading(false);
      return;
    } catch (error) {
      console.error("Error creating users document", error);
      setIsSubmitError(true);
      setIsSubmitSuccess(false);
      setLoading(false);
      return;
    }
  };

  return (
    <SectionWrapper id="hero">
      <Container>
        <Wrapper>
          <TextWrapper>
            <CheckMarkWrapper>
              <CheckMark src={CheckMarkImage} alt="checkmark" /> Beta coming
              soon!
            </CheckMarkWrapper>
            <H1 mb="25px">Find or Create Dance Events on the Spot</H1>
            <Text mb="35px" fontSize="19px" style={{lineHeight: 1.4}}>
              Find dance events by location, or create your own and sell
              tickets. Join the waiting list to try our{" "}
              <strong>free beta version!</strong>
            </Text>

            <Formik
              initialValues={{ email: "" }}
              validationSchema={SignInSchema}
              onSubmit={onSubmitForm}
            >
              {({ handleChange, errors, touched, values }) => (
                <Form>
                  <FormWrapper>
                    <InputWrapper>
                      <InputEmail
                        name="email"
                        type="text"
                        onChange={(e) => {
                          e.persist = () => {};
                          handleChange(e);
                          setIsSubmitError(false);
                          setIsSubmitSuccess(false);
                        }}
                        value={values.email}
                        placeholder="Your email address"
                      />
                      <TextError>
                        {errors.email && touched.email && errors.email}
                        {isSubmitError &&
                          "Something went wrong, please try again!"}
                      </TextError>
                      {isSubmitSuccess && (
                        <TextSuccess>
                          <CheckMarkSuccess
                            src={CheckMarkSuccessImage}
                            alt="submit success"
                          />
                          Email send to your inbox.
                        </TextSuccess>
                      )}
                    </InputWrapper>
                    <ButtonWrapper
                      style={{
                        marginBottom: `${
                          isSubmitSuccess || isSubmitError || errors.email
                            ? "0"
                            : "50px"
                        }`,
                      }}
                    >
                      <ButtonSubmit type="submit" disabled={loading}>
                        {loading && <Loader />}
                        {!loading && (
                          <>{isSubmitSuccess ? "Success!" : "Try beta"}</>
                        )}
                      </ButtonSubmit>
                    </ButtonWrapper>
                  </FormWrapper>
                </Form>
              )}
            </Formik>
          </TextWrapper>
          <Image src={HeroImage} alt="dancers" />
        </Wrapper>
      </Container>
    </SectionWrapper>
  );
};
