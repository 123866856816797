import React from 'react'

import { Item, ItemList } from './Item'

import { Container, Section } from '../../components/Layout'
import { H2, Text } from '../../components/Typography'

const dancersFAQ: ItemList = [
  {
    title: 'Is it possible to get a refund for an event ticket?',
    text: `Event organizers set their own refund policies. Before requesting a refund, first, check to see if the event organizer has a refund policy.`
  },
  {
    title: 'What payment methods do you accept?',
    text: `Currently, we accept PayPal, Mastercard, and Visa.`
  }
]

const teacherFAQ: ItemList = [
  {
    title: 'Is it possible to create free events on Dancly?',
    text: `Yes, you can create free events with the Dancly Pro account. `
  },
  {
    title: 'Is it possible to create online events?',
    text: `Yes, you can create online events with the Dancly Pro account. You can create free events, or request payments to sell tickets. `
  },
  {
    title: 'What is the minimum payout for events?',
    text: `The minimum payout for lessons is €50.`
  },
  {
    title: 'What payout methods are currently available?',
    text: `Currently, we support payments via PayPal, Mastercard, and Visa. `
  },
  {
    title: 'How long will I need to wait for my payout?',
    text: `The payout duration depends on the payment type you choose and your location. All payments should be processed within 3 business days. `
  },
  {
    title: 'How can I contact you?',
    text: `You can contact us through email via hello@dancly.com or our social media platforms.  `
  }
]

export const FAQ: React.FC = () => (
  <Section id="faq" py={{ _: '80px', lg: '110px' }}>
    <Container>
      <H2 mb={{ _: '20px', lg: '40px' }}>FAQ</H2>
      <Text
        fontSize={{ _: '24px', lg: '30px' }}
        color="primary"
        fontWeight="bold"
        mb={{ _: '5px', lg: '30px' }}
      >
        For attendees:
      </Text>
      {dancersFAQ.map((item, index) => (
        <Item key={index} {...item} />
      ))}
      <Text
        fontSize={{ _: '24px', lg: '30px' }}
        color="primary"
        fontWeight="bold"
        mb={{ _: '20px', lg: '40px' }}
      >
        For event organizers:
      </Text>
      {teacherFAQ.map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </Container>
  </Section>
)
