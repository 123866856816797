import * as React from 'react'

type ProjectContext = {
  isMenuVisible: boolean
  toggleMenuVisibility: () => void
}

export const ProjectContext = React.createContext<ProjectContext>({
  isMenuVisible: false,
  toggleMenuVisibility: () => null
})

export const ProjectContextProvider: React.FC = ({ children }) => {
  const [isMenuVisible, setMobileMenuVisibility] = React.useState(false)

  const toggleMenuVisibility = () => {
    setMobileMenuVisibility(!isMenuVisible)
  }

  return (
    <ProjectContext.Provider
      value={{
        isMenuVisible,
        toggleMenuVisibility
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}
