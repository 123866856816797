import React from 'react'
import styled from '@emotion/styled'

import { Item } from './Item'

import { Container, Section } from '../../components/Layout'
import { H2 } from '../../components/Typography'
import Audrius from '../../images/audrius.png'
import Justinacka from '../../images/justinacka.png'
import Gabija from '../../images/gabija.png'
import Zbignev from '../../images/Zbignev-stankevic.png'
import { Theme } from '../../theme'

const teamList = [
  {
    img: Audrius,
    altText: 'person photo',
    position: 'CEO, Co-founder',
    name: 'Audrius Alisauskas'
  },
  {
    img: Justinacka,
    altText: 'person photo',
    position: 'Head of design, Co-founder',
    name: 'Justina Smagurauskaite'
  },
  {
    img: Gabija,
    altText: 'person photo',
    position: 'COO, Co-founder',
    name: 'Gabija Fatenaite'
  },
  {
    img: Zbignev,
    altText: 'person photo',
    position: 'Fullstack developer',
    name: 'Zbignev Stankevic'
  }
]

const ItemWrapper = styled.div<Theme>`
  display: grid;
  grid-gap: 100px;
  grid-template-columns: repeat(4, 1fr);

  ${(props) => props.theme.mediaQueries.sm} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`

export const TeamBlock: React.FC = () => (
  <Section background="#111214" pt={{ _: '100px', lg: '120px' }} id="team">
    <Container>
      <H2
        mb={{ _: '45px', lg: '80px' }}
        textAlign="center"
        color="textSecondary"
      >
        Meet the team behind Dancly
      </H2>
      <ItemWrapper>
        {teamList.map((item, key) => (
          <Item {...item} key={key} />
        ))}
      </ItemWrapper>
    </Container>
  </Section>
)
