import React from 'react'
import styled from '@emotion/styled'
import { Theme } from '@theme'

import { Text, H3 } from '../../components/Typography'

export type ItemList = Props[]

type Props = {
  title: string
  text: string
}

const Wrapper = styled.div<Theme>`
  position: relative;
  padding-left: 40px;
  margin-bottom: 40px;

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: 5px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(p) => p.theme.colors.primary};
  }

  ${(props) => props.theme.mediaQueries.sm} {
    padding-left: 0;

    &:before {
      display: none;
    }
  }
`

export const Item: React.FC<Props> = ({ text, title }) => (
  <Wrapper>
    <H3 mb="20px">{title}</H3>
    <Text>{text}</Text>
  </Wrapper>
)
