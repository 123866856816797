import React from 'react'
import styled from '@emotion/styled'

import { Theme } from '../../theme'
import { Grid, Container } from '../../components/Layout'
import { MediaList } from '../Footer/MediaList'

const Wrapper = styled.div<Theme>`
  background: ${(props) => props.theme.colors.canvas};
  height: calc(100vh - 86px);
  width: 100%;
  z-index: ${(props) => props.theme.zIndex.mobileNav};
  position: fixed;
`

const HeaderItem = styled.a<Theme>`
  text-decoration: none;
  font-size: 20px;
  color: ${(props) => props.theme.colors.textPrimary};

  &:last-child {
    margin-right: 0;
  }
`

const StyledContainer = styled(Container)<Theme>`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props) => props.theme.mediaQueries.sm} {
    padding: 20px 35px 40px;
  }
`

const StyledGrid = styled(Grid)`
  height: 100%;
`

type Props = {
  menuItems: {
    title: string
    url: string
  }[]
  onClick: () => void
}

export const MobileMenu: React.FC<Props> = ({ menuItems = [], onClick }) => (
  <Wrapper>
    <StyledGrid>
      <StyledContainer>
        <Grid gridGap="20px">
          {menuItems.map(({ title, url }, index) => (
            <HeaderItem onClick={onClick} href={url} key={index}>
              {title}
            </HeaderItem>
          ))}
        </Grid>
        <MediaList />
      </StyledContainer>
    </StyledGrid>
  </Wrapper>
)
