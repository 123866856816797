import React from 'react'
import styled from '@emotion/styled'

import { Text } from '../../components/Typography'
import { Theme } from '../../theme'

type Props = {
  img: string
  altText: string
  name: string
  position: string
}

export const Wrapper = styled.div<Theme>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-self: center;
`

export const Image = styled.img<Theme>`
  max-width: 150px;
  margin-bottom: 30px;

  ${(props) => props.theme.mediaQueries.sm} {
    max-width: 200px;
  }
`

export const Item: React.FC<Props> = ({ name, position, img, altText }) => (
  <Wrapper>
    <Image src={img} alt={altText} />
    <Text mb="5px" fontWeight="bold" textAlign="center" color="textSecondary">
      {position}
    </Text>
    <Text textAlign="center" color="textSecondary">
      {name}
    </Text>
  </Wrapper>
)
