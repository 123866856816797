import React from 'react'
import styled from '@emotion/styled'

import { Theme } from '../../theme'

const Wrapper = styled.div`
  padding: 10px 10px;
  cursor: pointer;
`

const Burger = styled.div<Theme & { isActive }>`
  position: relative;
  width: 20px;
  height: 1px;
  border: 1px solid black;
  transform: ${({ isActive }) =>
    isActive ? 'translate3d(0,0,0) rotate(45deg)' : 'none'};
  transition: transform 0.2s ease;

  &::before,
  &::after {
    content: '';
    width: 100%;
    border: 1px solid black;
    position: absolute;
    left: -1px;
  }

  &::before {
    top: -7px;
    opacity: ${({ isActive }) => (isActive ? 0 : 1)};
  }

  &::after {
    top: 5px;
    transform: ${({ isActive }) =>
      isActive ? 'translate3d(0,-6px,0) rotate(-90deg)' : 'none'};
  }
`

type Props = {
  onClick: () => void
  isActive: boolean
}

export const Hamburger: React.FC<Props> = ({ onClick, isActive }) => (
  <Wrapper onClick={onClick}>
    <Burger isActive={isActive} />
  </Wrapper>
)
