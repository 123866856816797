import styled from '@emotion/styled'

import { Theme } from '../../theme'

export const Container = styled.div<Theme>`
  max-width: 980px;
  margin: 0 auto;
  padding: 0 50px;

  ${(props) => props.theme.mediaQueries.sm} {
    padding: 0 35px;
    overflow: hidden;
  }
`
