import styled from '@emotion/styled'
import {
  flexbox,
  FlexboxProps,
  padding,
  PaddingProps,
  margin,
  MarginProps
} from 'styled-system'

export const Flex = styled.div<FlexboxProps & PaddingProps & MarginProps>`
  display: flex;
  ${flexbox}
  ${padding}
  ${margin}
`
