import React from 'react'
import styled from '@emotion/styled'

export type MediaItem = {
  img: string
  altText: string
  link: string
}

export const Link = styled.a`
  margin-right: 40px;

  &:last-child {
    margin-right: 0;
  }
`

export const Item: React.FC<MediaItem> = ({ link, img, altText }) => (
  <Link href={link} target="_blank" rel="noreferrer noopener">
    <img src={img} alt={altText} />
  </Link>
)
