import React from 'react'
import styled from '@emotion/styled'

import { Text } from '../../components/Typography'
import { Theme } from '../../theme'

type Props = {
  img: string
  altText: string
  text: string
}

export const Wrapper = styled.div<Theme>`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 220px;
  justify-self: center;
`

export const Image = styled.img<Theme>`
  margin-bottom: 10px;
  max-width: 70px;
`

export const Item: React.FC<Props> = ({ text, img, altText }) => (
  <Wrapper>
    <Image src={img} alt={altText} />
    <Text textAlign="center" color="textSecondary">
      {text}
    </Text>
  </Wrapper>
)
