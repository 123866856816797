import React from 'react'
import styled from '@emotion/styled'

import { MediaList } from './MediaList'

import { Container, Section } from '../../components/Layout'
import { Text } from '../../components/Typography'
import { Theme } from '../../theme'

const ContactUsLink = styled.a<Theme>`
  text-decoration: none;
  color: ${(props) => props.theme.colors.primary};
  &:hover {
    text-decoration: underline;
  }
`

const Wrapper = styled.div<Theme>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${(props) => props.theme.mediaQueries.sm} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const TextWrapper = styled.div<Theme>`
  max-width: 330px;

  ${(props) => props.theme.mediaQueries.sm} {
    text-align: center;
    margin-bottom: 55px;
  }
`

export const Footer: React.FC = () => (
  <Section background="#111214" py={{ _: '100px', lg: '120px' }}>
    <Container>
      <Wrapper id="contactUs">
        <TextWrapper>
          <Text
            fontSize="30px"
            mb="10px"
            fontWeight="bold"
            color="textSecondary"
          >
            Contact us
          </Text>
          <Text fontSize="20px" color="textSecondary">
            Have any questions? Feel free to contact us via{' '}
            <ContactUsLink href="mailto:hello@dancly.com">
              hello@dancly.com
            </ContactUsLink>{' '}
            or any social media platform.
          </Text>
        </TextWrapper>
        <MediaList color="textSecondary" />
      </Wrapper>
    </Container>
  </Section>
)
