import { MarginProps, PaddingProps } from 'styled-system'

export const config = {
  fontSizes: [18, 20, 24, 30, 48, 55],
  breakpoints: ['40em', '52em', '64em'],
  colors: {
    textPrimary: '#201427',
    textSecondary: '#ffffff',
    primary: '#9B765F',
    canvas: '#ffffff'
  },
  fontFamily: 'futura-pt',
  zIndex: {
    header: 7000,
    mobileNav: 6500
  }
}

export const theme = {
  ...config,
  breakpoints: {
    sm: config.breakpoints[0],
    md: config.breakpoints[1],
    lg: config.breakpoints[2]
  },
  mediaQueries: {
    sm: `@media screen and (max-width: ${config.breakpoints[0]})`,
    md: `@media screen and (max-width: ${config.breakpoints[1]})`,
    lg: `@media screen and (min-width: ${config.breakpoints[2]})`
  }
}

export type ThemeType = typeof theme & MarginProps & PaddingProps

export type Theme = {
  theme?: ThemeType
}
