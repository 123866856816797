import styled from '@emotion/styled'
import {
  grid,
  GridProps,
  flex,
  FlexProps,
  padding,
  PaddingProps,
  margin,
  MarginProps
} from 'styled-system'

export const Grid = styled.div<
  GridProps & PaddingProps & MarginProps & FlexProps
>`
  display: grid;
  ${grid}
  ${padding}
  ${margin}
  ${flex}
`
