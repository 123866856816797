import React from 'react'
import styled from '@emotion/styled'
import { Theme } from '@theme'

import { Container, Section } from '../components/Layout'
import { Text, H2 } from '../components/Typography'
import CheckMarkImage from '../images/svg/check-mark.svg'
import DancersImage from '../images/jonante+leo.png'

const TextWrapper = styled.div<Theme>`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  ${(props) => props.theme.mediaQueries.sm} {
    text-align: center;
    align-items: center;
  }
`

const NameWrapper = styled.div<Theme>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;

  &::before {
    content: '&';
    position: absolute;
    font-size: 18px;
    font-weight: bold;
    top: 50%;
    left: 50%;
    transform: translate(50%, -50%);
  }

  ${(props) => props.theme.mediaQueries.sm} {
    flex-direction: column;

    &::before {
      transform: translate(-50%, -70%);
    }

    p {
      margin-bottom: 30px;

      &:last-child: {
        margin: 0;
      }
    }
  }
`
const Wrapper = styled.div<Theme>`
  display: grid;
  grid-template-columns: 400px 350px;
  grid-gap: 90px;
  justify-items: end;
  justify-content: center;

  ${(props) => props.theme.mediaQueries.sm} {
    grid-gap: 25px;
    grid-template-columns: 1fr;
  }
`
const Dancers = styled.img`
  max-width: 400px;
  width: 100%;
`

const CheckMark = styled.img`
  margin-bottom: 10px;
`

export const SocialApprovement: React.FC = () => (
  <Section pt={{ _: '50px', lg: '90px' }} id="ourAmbassadors">
    <Container>
      <Wrapper>
        <Dancers src={DancersImage} alt="two people standing" />
        <TextWrapper>
          <CheckMark src={CheckMarkImage} alt="checkmark" />
          <H2 mb="10px">
            Approved <br />
            by the best
          </H2>
          <Text mb={{ _: '15px', lg: '10px' }}>
            Dancly is used and approved by award-winning professional dancers!
          </Text>
          <NameWrapper>
            <Text fontWeight="bold">
              Jomante <br /> Dapkeviciute
            </Text>
            <Text fontWeight="bold">Leo Cato</Text>
          </NameWrapper>
        </TextWrapper>
      </Wrapper>
    </Container>
  </Section>
)
